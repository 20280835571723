<template>
  <div class="company__page">
    <div class="company__page-breadcrumbs">
      <b-breadcrumb :items="items"></b-breadcrumb>
    </div>
    <div class="company__page-content company__page-content--margin-top">
      <div class="company__page-about">
        <div class="company__page-left-section">
          <img class="" :src="company.media[0].original_url" />
        </div>
        <div class="company__page-right-section">
          <div>
            <hr class="company-page-line" />
          </div>
          <div class="company__page-description" v-html="company.content"></div>
        </div>
      </div>
    </div>
    <div v-dragscroll class="company__page-history">
      <div v-for="item in history" :key="item.id">
        <img
          v-if="item.types === 'company'"
          class="custom-w"
          :src="item.media[0].original_url"
        />
      </div>
    </div>
    <div class="company__page-content">
      <div class="company__page-team">
        <CompanyTeam />
      </div>
    </div>
      <div ref="footerElement" ></div>
    <div class="company__page">
      <div class="company__page-csr">
        <CompanyCsr />
      </div>
    </div>
  </div>
</template>

<script>
import CompanyTeam from "@/components/CompanyTeam.vue";
import CompanyCsr from "@/components/CompanyCsr.vue";
import Admin from "@/apis/Admin.js";

export default {
  data() {
    return {
      items: [
        {
          text: "Compania",
          active: true,
        },
      ],
      company: [],
      history: [],
    };
  },
  mounted() {
    const source = this.$route.query.source;
    this.$nextTick(() => {
      this.getCompany();
      this.getHistory();
      setTimeout(() => {
        if (source === "footer") {
          const footerElement = this.$refs.footerElement;
          if (footerElement) {
            footerElement.scrollIntoView({
              block: "center",
              behavior: "smooth",
            });
          }
        }
      }, 1000);
    });
  },
  components: {
    CompanyTeam,
    CompanyCsr,
  },
  methods: {
    getCompany() {
      const companyName = this.$router.history.current.params.slug;
      Admin.getCompany(companyName).then((response) => {
        this.company = response.data;
        this.items[0].text = this.company.name;
      });
    },
    getHistory() {
      Admin.getHistory().then((response) => {
        this.history = response.data;
      });
    },
  },
};
</script>

<style>
.company__page {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.company__page-breadcrumbs {
  height: 206px;
  display: flex;
  justify-content: center;
  align-items: end;
  background: url(./../../assets/images/compania/bread-compania.png);
}

.breadcrumb {
  width: 1171px;
  background: transparent;
  margin-bottom: 42px;
}

ol.breadcrumb {
  padding: 12px 0px !important;
}

.breadcrumb span {
  color: #fffefe;
  font-family: "XMH Flama-Basic";
  font-size: 29px;
  line-height: 75.79px;
  text-transform: uppercase;
  line-height: normal;
  margin-bottom: 23px;
}

.company__page-content {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 45px 0px 60px 0px;
}

.company__page-content--margin-top {
  margin: 100px 0px 60px 0px;
}

.company__page-about {
  width: 1171px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 0px;
}

.company__page-description-title {
  width: 365px;
  color: #4d4d4e;
  font-size: 35px;
  margin: 20px 0px 40px 0px;
  font-family: "XMH Flama-Medium";
  font-size: 35px;
  font-weight: 500;
  line-height: 40px;
  text-transform: uppercase;
  letter-spacing: -0.58px;
}

.company__page-description_paragraph-1 {
  margin-bottom: 25px;
  color: #5c5c5c;
  font-family: "XMH Flama-Medium";
  font-size: 19px;
  letter-spacing: -0.19px;
  line-height: 27px;
}

.company__page-description_paragraph-2 {
  font-family: "OpenSans-Bold";
  margin: 0px;
  font-size: 17px;
  color: #5c5c5c;
}

.company__page-description_paragraph-3 {
  color: #5c5c5c;
  font-family: "OpenSans-Regular";
  letter-spacing: -0.17px;
  line-height: 25px;
  font-size: 17px;
}

.company-page-line {
  height: 3px;
  margin: 0px 0px 20px 0px;
  border-top: 3px solid rgba(0, 0, 0, 0.1);
}

.custom-width-description {
  width: 103%;
}

.company__page-history {
  overflow: hidden;
  cursor: url("./../../assets/images/drag_img_color.png") 10 3, auto !important;
  background-color: #606060;
  padding: 45px 0px;
}

.company__page-history img {
  width: 2915px;
}

.company__page-team {
  width: 1171px;
}

.company__page-left-section img {
  width: 90%;
}

@media (max-width: 1540px) {
  .custom-width-description {
    width: 100%;
  }
}

@media (max-width: 1440px) {
  .company__page-description-title {
    width: 100%;
  }
}

@media (max-width: 1366px) {
  .company__page-about,
  .breadcrumb,
  .company__page-team,
  .company__csr-application {
    width: 85% !important;
  }
  .company__page-left-section img {
    width: 95%;
  }

  .company__team-title {
    font-size: 30px;
  }
  .team-text p {
    font-size: 17px;
  }
  .company__page-description h2 {
    font-size: 30px;
    width: 60%;
  }
  .company__team-responsability-title span {
    font-size: 30px;
  }
  .company__page-description p {
    font-size: 15px;
    width: 87%;
  }
  .company__team-responsability-text {
    width: 39%;
    line-height: 24px;
    margin-top: 40px;
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  .company__page-left-section img {
    width: 100%;
  }

  .company__page-history img {
    width: 1900px;
  }
  .company__page-description p {
    font-size: 14px;
    width: 100%;
  }
  .company__page-description h2 {
    font-size: 24px;
  }
  .company__team-title,
  .company__team-responsability-title span {
    font-size: 28px;
  }
  .team-text p {
    font-size: 16px;
  }
  .company__team-responsability-text {
    width: 53%;
    line-height: 24px;
    margin-top: 40px;
    font-size: 15px;
  }
  .company__team-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 767px) {
  .company__page-about {
    grid-template-columns: repeat(1, 1fr);
  }
  .company__page-content--margin-top {
    margin: 55px 0px 60px 0px;
  }
  .team-text,
  .company__team-responsability-text {
    width: 100%;
  }
  .company__team-name {
    width: 29%;
    font-size: 17px;
  }
  .company__team-job {
    font-size: 12px;
    margin: 0px 0px 8px 0px;
  }
  .company__team-email p {
    font-size: 12px;
    line-height: 0px;
  }
  .company__team-list {
    grid-template-columns: repeat(2, 1fr);
  }
  .company__team-image img {
    width: 230px;
    height: 230px;
  }

  .company__page-breadcrumbs .breadcrumb span {
    font-size: 28px;
  }
}

@media (max-width: 600px) {
  .company__team-image img {
    width: 200px;
    height: 200px;
  }
  .company__team-list {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
}

@media (max-width: 500px) {
  .company__team-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .company__team-title,
  .company__team-responsability-title span {
    font-size: 25px;
    line-height: 30px;
  }
  .company__team-responsability-text {
    margin-top: 28px;
    font-size: 14px;
  }
  .company__team-name {
    width: 20%;
  }

  .company__team-image img {
    width: 100%;
    height: auto;
  }
}
</style>