<template>
  <div>
    <div class="careers__page-breadcrumbs">
      <b-breadcrumb :items="items"></b-breadcrumb>
    </div>
    <div class="careers__page-container">
      <div class="careers__page-content">
        <div>
          <p class="careers__page-first-paragraph">
            Toate echipele noastre, fie că proiectează sau sunt pe șantier, au
            două calități importante: specializarea și deschiderea către dialog.
            Reușita proiectelor noastre ține de specializare și de modul în care
            găsim soluții adaptate pe fiecare caz în parte, în timpul cel mai
            scurt.
          </p>
        </div>

        <div class="careers__page-jobs">
          <div
            class="careers__page-jobs-item"
            v-for="item in careers"
            :key="item.id"
          >
            <div class="careers__page-image">
              <img :src="item.media[0].original_url" />
            </div>
            <div class="careers__page-description">
              <div class="jobs-title">
                <h1>{{ item.title }}</h1>
              </div>
              <div class="jobs-deadline">
                <h3>{{ item.deadline }}</h3>
              </div>
              <div class="jobs-description">
                <span style="font-family: XMH Flama-Medium"
                  >{{ $t("companyPage.require") }}:</span
                >
                <div v-html="item.description"></div>
              </div>
            </div>
            <div class="careers__page-button">
              <router-link :to="'/cariere/' + item.title">
                <button class="careers__page-apply">
                  {{ $t("companyPage.apply") }}
                </button></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Admin from "@/apis/Admin.js";
export default {
  name: "careers-page",
  data() {
    return {
      careers: [],
      items: [
        {
          text: "Cariere",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getCareers();
    });
  },
  methods: {
    getCareers() {
      Admin.getCareers().then((response) => {
        this.careers = response.data;
      });
    },
  },
};
</script>

<style>
.jobs-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
}
.breadcrumb {
  width: 1171px;
  background: transparent;
  margin: 0;
  padding: 0;
}

.careers__page-breadcrumbs {
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(./../../assets/images/cariere/bread-cariere.jpg);
  background-size: cover;
}

.breadcrumb span {
  font-size: 35px;
  text-transform: uppercase;
  color: white;
  font-family: "XMH Flama-Medium";
}

.breadcrumb-item {
  font-size: 32px;
}

.careers__page-first-paragraph {
  font-family: "XMH Flama-Basic";
  width: 68%;
  margin: 60px 0px;
  color: #4d4d4e;
  max-width: 100%;
  font-size: 20px;
  line-height: 26px;
}

.jobs-title h1 {
  font-family: "XMH Flama-Medium";
  font-size: 32px;
  margin-bottom: 5px;
  color: #4d4d4e;
}

.jobs-deadline h3 {
  color: #00aad7;
  font-size: 18px;
  font-family: "XMH Flama-Medium";
}

.jobs-description p {
  font-family: "XMH Flama-Light";
  font-size: 16px;
  color: #8a8d8f;
}

.careers__page-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.careers__page-content {
  width: 1171px;
  margin-bottom: 60px;
}

.careers__page-image img {
  width: 150px;
  height: auto;
}

.careers__page-description {
  margin: 0px 20px;
  width: 100%;
}

.careers__page-button {
  margin: 0px 20px;
  display: flex;
  align-items: flex-end;
}

.careers__page-jobs-item {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
}

.careers__page-apply {
  font-family: "XMH Flama-Medium";
  width: 170px;
  height: 46px;
  text-transform: uppercase;
  background-color: #00aad7;
  color: white;
  border: none;
  margin: 20px 0px;
  font-size: 13px;
  padding-top: 6px;
}

.careers__page-apply:hover {
  border: 1px solid #00aad7;
  background-color: white;
  color: #00aad7;
}

.separator-careers {
  margin: 50px 0px;
  border: 0;
  border-top: 3px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 1366px) {
  .careers__page-content {
    width: 85%;
  }
  .careers__page-breadcrumbs {
    height: 300px;
  }
}

@media (max-width: 992px) {
  .careers__page-first-paragraph {
    margin: 50px 0px;
    font-size: 18px;
    line-height: 24px;
  }
  .jobs-title h1 {
    font-size: 24px;
  }
  .jobs-deadline h3 {
    font-size: 16px;
  }
  .jobs-description p {
    font-size: 14px;
  }
  .careers__page-apply {
    width: 150px;
    height: 40px;
    font-size: 12px;
    padding-top: 3px;
  }
}

@media (max-width: 767px) {
  .careers__page-first-paragraph {
    font-size: 16px;
    line-height: 22px;
    width: 100%;
  }
  .careers__page-jobs-item {
    flex-wrap: wrap;
    row-gap: 30px;
  }
  .careers__page-description,
  .careers__page-button {
    margin: 0px;
  }
  .careers__page-apply {
    margin: -7px 0px 35px 0px;
  }

  .careers__page-breadcrumbs .breadcrumb span {
    font-size: 28px;
  }
  .careers__page-breadcrumbs {
    height: 180px;
  }
}
</style>