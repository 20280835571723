<template>
  <div class="apply__now">
    <div class="apply__now-breadcrumbs">
      <b-breadcrumb :items="items"></b-breadcrumb>
    </div>
    <div class="apply__now-container">
      <div class="apply__now-content">
        <div class="apply__now-jobs">
          <div class="apply__now-jobs-item">
            <div class="apply__now-image">
              <img :src="career[0].media[0].original_url" />
            </div>
            <div class="apply__now-description">
              <div class="jobs-title">
                <h1>{{ career[0].title }}</h1>
              </div>
              <div class="jobs-deadline">
                <h3>{{ career[0].deadline }}</h3>
              </div>
              <div class="jobs-description">
                <p v-html="career[0].description"></p>
              </div>
            </div>
          </div>
          <hr />
          <div class="apply__now-form">
            <ApplyForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApplyForm from "./apply-form.vue";
import Admin from "@/apis/Admin";

export default {
  data() {
    return {
      career: [],
      items: [
        {
          text: "Compania",
          href: "",
        },
        {
          text: "Cariere",
          href: "/cariere",
        },
      ],
      companyLangs: {
        ro: "compania",
        en: "the-company",
        de: "das-unternehmen",
        fr: "la-société",
      },
      lang: localStorage.getItem("lang"),
    };
  },
  components: {
    ApplyForm,
  },
  mounted() {
    this.$nextTick(() => {
      this.getSingleCareer(this.$router.history.current.params.title);
    });
  },
  methods: {
    getSingleCareer(title) {
      Admin.getSingleCareer(title).then((response) => {
        this.career = response.data;
        this.items[0].href = "/company/" + this.companyLangs[this.lang];
      });
    },
  },
};
</script>
<style scoped>
.apply__now-form {
  width: 75%;
  margin-top: 70px;
}
.apply__now-breadcrumbs {
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: url(./../../assets/images/cariere/pag-cariere.jpg);
  background-size: cover;
}

.breadcrumb {
  width: 1171px;
  background: transparent;
  margin-bottom: 5rem;
}

.breadcrumb span,
.breadcrumb-item a {
  color: white;
  margin-bottom: 0px;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: white;
}

.breadcrumb li {
  font-size: 35px;
  text-transform: uppercase;
  font-family: "XMH Flama-Medium";
}

.jobs-title h1 {
  font-family: "XMH Flama-Medium";
  font-size: 32px;
  color: #4d4d4e;
}

.jobs-deadline h3 {
  font-family: "XMH Flama-Medium";
  color: #00aad7;
  font-size: 18px;
}

.jobs-description p {
  font-family: "XMH Flama-Light";
  color: #a0a4a6;
}

.apply__now-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.apply__now-content {
  width: 1171px;
}

.apply__now-image img {
  width: 150px;
  height: auto;
}

.apply__now-jobs-item {
  display: grid;
  grid-template-columns: 20% 75%;
  grid-template-rows: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 0px;
  margin: 80px 0px 50px 0px;
}

@media (max-width: 1366px) {
  .apply__now-content {
    width: 85%;
  }

  .apply__now-breadcrumbs {
    height: 300px;
  }
  .apply__now-breadcrumbs .breadcrumb span {
    font-size: 27px;
    margin-bottom: 0px;
  }
}

@media (max-width: 992px) {
  .jobs-title h1 {
    font-size: 28px;
  }
  .jobs-deadline h3 {
    font-size: 16px;
  }

  .apply__now-jobs-item {
    grid-column-gap: 25px;
    margin: 60px 0px 40px 0px;
  }
}

@media (max-width: 850px) {
  .apply__now-jobs-item {
    grid-template-columns: repeat(1, 1fr);
  }
  .apply__now-image {
    margin-bottom: 26px;
  }
  .apply__now-breadcrumbs {
    height: 267px;
  }
  .apply__now-jobs-item {
    margin: 50px 0px 50px 0px;
  }
}
</style>