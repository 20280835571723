import { render, staticRenderFns } from "./IndustrialWalls.vue?vue&type=template&id=816cbdf8"
import script from "./IndustrialWalls.vue?vue&type=script&lang=js"
export * from "./IndustrialWalls.vue?vue&type=script&lang=js"
import style0 from "./IndustrialWalls.vue?vue&type=style&index=0&id=816cbdf8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports