import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import VueCarousel from 'vue-carousel'
import VueDragscroll from 'vue-dragscroll'
import { CarouselPlugin } from 'bootstrap-vue'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import VueEasyLightbox from 'vue-easy-lightbox'
import VueI18n from 'vue-i18n'
import { languages } from './locales/index.js'
import { defaultLocale } from './locales/index.js'

const messages = Object.assign(languages)

delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('./assets/images/marker-icon-blue.png'),
  iconUrl: require('./assets/images/marker-icon-blue.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCarousel)
Vue.use(VueDragscroll)
Vue.use(CarouselPlugin)
Vue.use(CoolLightBox)
Vue.use(VueEasyLightbox)
Vue.use(VueI18n);

Vue.mixin({
  created() {
    this.isMobile()
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
  },
})

require('./alerts.js');

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages
});
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app')
