<template>
  <div class="company__home">
    <div class="company__home-partners d-flex" id="partners-carousel">
      <CarouselPartners />
    </div>
    <div class="company__home-about">
      <div class="company__home-left-section">
        <img class="mw-100" src="../assets/images/homepage-company.png" />
      </div>
      <div class="company__home-right-section">
        <div>
          <h1 class="line-after company__home-title">
            <span> {{ $t("homepage.header.menuValues.company") }} </span>
          </h1>
        </div>
        <div class="company__home-description">
          <h2 class="company__description-title">
            {{ company.homepage_title }}
          </h2>
          <p
            class="company__description_paragraph"
            v-html="company.homepage_content"
          ></p>
          <a
            class="company__home-button"
            :href="'/company/' + companyLangs[lang]"
            >{{ $t("homepage.buttons.details") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselPartners from "@/components/slide/CarouselPartners.vue";
import Admin from "@/apis/Admin.js";

export default {
  data() {
    return {
      companyLangs: {
        ro: "compania",
        en: "the-company",
        de: "das-unternehmen",
        fr: "la-société",
      },
      lang: localStorage.getItem("lang") || "fr",
      company: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getCompany();
    });
  },
  methods: {
    getCompany() {
      const companyName = this.companyLangs[this.lang];
      Admin.getCompany(companyName).then((response) => {
        this.company = response.data;
      });
    },
  },
  components: {
    CarouselPartners,
  },
};
</script>

<style scoped>
@import "../assets/styles/company.css";
</style>