<template>
  <b-container>
    <div>
      <h1 class="line-before content__values-title">
        <span> {{ $t("homepage.valuesAv") }} </span>
      </h1>
    </div>
    <b-row>
      <b-col cols="12 carousel_padding">
        <carousel class="values_desktop" :perPage="1" v-model="index">
          <slide v-for="item in items" :key="item.id">
            <b-card
              class="mask"
              :img-src="item.media[0].original_url"
              img-alt="Image"
            >
            </b-card>
            <b-card class="text slide_1" :title="item.name">
              <b-card-text>{{ item.subtitle }}</b-card-text>
            </b-card>
          </slide>
        </carousel>
        <carousel class="values_mobile" :perPage="1">
          <slide class="slide-mobile" v-for="item in items" :key="item.id">
            <b-card
              class="mask"
              :img-src="item.media[0].original_url"
              img-alt="Image"
            >
            </b-card>
            <b-card class="card-mobile-text" :title="item.name">
              <b-card-text>{{ item.subtitle }}</b-card-text>
            </b-card>
          </slide>
        </carousel>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Admin from "@/apis/Admin.js";
export default {
  data() {
    return {
      items: [],
      index: 0, // Indexul curent al slide-ului
      direction: null,
      slideInterval: null, // Aici vom stoca referința intervalului
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getHomepageValues();
      this.startAutoSlide();
    });
  },
  beforeDestroy() {
    // Curățăm intervalul când componenta este distrusă
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  },
  methods: {
    getHomepageValues() {
      Admin.getHomepageValues().then((response) => {
        this.items = response.data;
      });
    },
    startAutoSlide() {
      // Setăm intervalul
      this.slideInterval = setInterval(() => {
        // Incrementăm indexul sau îl resetăm la 0 dacă a ajuns la sfârșitul listei
        this.index = (this.index + 1) % this.items.length;
      }, 4000); // 4000ms = 4 secunde
    },
  },
};
</script>

<style>
.text {
  width: 40%;
  margin-bottom: 3%;
}

.card-img {
  max-height: 385px;
}

.VueCarousel-slide {
  height: max-content;
}

.VueCarousel-pagination {
  width: 62% !important;
  text-align: end !important;
}

.card {
  border: none;
}

.card-mobile-text {
  width: 100%;
  margin-top: 20px;
}

.text-slide {
  margin-bottom: 3%;
}

.slide-mobile {
  display: flex !important;
  flex-direction: column !important;
}

.VueCarousel-slide {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.card-body {
  padding: 0;
}

.card-title {
  color: #03aad9;
  font-size: 35px;
  font-family: "XMH Flama-Medium";
  padding-right: 70px;
}

.card-text {
  color: #03aad9;
  font-size: 35px;
  font-family: "XMH Flama-Light" !important;
}

.VueCarousel-dot {
  margin-top: 0px !important;
  border-radius: 0px !important;
  width: 9px !important;
  height: 9px !important;
}

.VueCarousel-dot--active {
  background-color: #01aad7 !important;
}

.VueCarousel-dot:focus {
  outline: none !important;
}

@media (max-width: 1640px) {
  .content__values > .container {
    width: 110% !important;
    max-width: 110% !important;
    margin-left: -10% !important;
  }
  .content__values {
    width: 1170px;
  }
  .VueCarousel-pagination {
    width: 63% !important;
  }
}

@media (max-width: 1620px) {
  .mask {
    width: 540px !important;
  }
  .card-img {
    object-fit: contain;
  }
  .VueCarousel-inner {
    width: 101%;
  }
}

@media (max-width: 1530px) {
  .content__values > .container {
    width: 105% !important;
    max-width: 105% !important;
    margin-left: -5% !important;
  }
}

@media (max-width: 1445px) {
  .card-title {
    font-size: 30px;
  }
  .card-text {
    font-size: 25px;
  }
}

@media (min-width: 1295px) and (max-width: 2000px) {
  .card-img,
  .card-img-top,
  .card-img-bottom {
    width: initial !important;
  }
}

@media (max-width: 1366px) {
  .card-img {
    height: 300px;
  }
}

@media (max-width: 1024px) {
  .card-title,
  .card-text {
    font-size: 20px;
  }
  .card-img {
    height: auto;
  }
  .mask {
    width: 420px;
  }
  .card-img {
    object-fit: cover;
  }
}

@media (max-width: 992px) {
  .card-title {
    font-size: 24px;
  }
  .card-text {
    font-size: 21px;
  }
}

@media (min-width: 750px) {
  .values_mobile {
    display: none !important;
  }
}

@media (max-width: 750px) {
  .card-img {
    height: auto;
  }
  .mask {
    width: 100% !important;
  }
  .card-title,
  .card-text {
    font-size: 20px !important;
  }
}

@media (max-width: 749px) {
  .values_desktop {
    display: none !important;
  }
}
</style>