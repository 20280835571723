<template>
  <div class="ag__partnership-page">
    <div class="ag__partnership-breadcrumbs">
      <b-breadcrumb :items="items"></b-breadcrumb>
    </div>
    <div class="ag__partnership-content">
      <div class="ag__partnership-services row">
        <div class="col-md-6 ag__partnership-services-left-column">
          <div class="ag__partnership-title">
            <h1 class="line-after">
              {{ $t("homepage.header.menuValues.services") }}
            </h1>
          </div>
          <div class="ag__partnership-subtitle">
            <h2>{{ service.name }}</h2>
          </div>
          <div class="ag__partnership-text" v-html="service.content"></div>
        </div>
        <div class="col-md-6 ag__partnership-services-right-column">
          <div class="ag__partnership-services-image">
            <img :src="mainImage" />
            <figcaption class="ag__partnership-figcaption">
              {{ service.image_title }}
            </figcaption>
          </div>
        </div>
      </div>
    </div>

    <AgPartnershipLightbox />

    <div
      class="ag__partnership-more-services w-100 d-flex justify-content-center"
    >
      <div class="other__services-list">
        <div>
          <h1 class="line-after other__services-title">
            <span> {{ $t("homepage.anotherServ") }} </span>
          </h1>
        </div>
        <div class="other__services-item">
          <div
            class="other__services-description"
            v-for="item in services.slice().reverse()"
            :key="item.id"
            v-show="
              item.vue_components === 'project-management' ||
              item.vue_components === 'maintenance-page'
            "
          >
            <a :href="item.slug + '&component=' + item.vue_components">
              <div v-for="media in item.media" :key="media.id">
                <img
                  v-if="media.collection_name === 'main_image'"
                  class="w-100"
                  :src="media.original_url"
                />
              </div>
              <h5 class="other__services-h5">{{ item.name }}</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgPartnershipLightbox from "@/components/AgPartnershipLightbox.vue";
import Admin from "@/apis/Admin.js";

export default {
  components: { AgPartnershipLightbox },
  name: "ag-partnership",
  data() {
    return {
      items: [],
      service: [],
      mainImage: "",
      randomServices: [],
      services: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getAG();
      this.getAllServices();
    });
  },
  methods: {
    getAG() {
      const serviceName =
        this.$router.history.current.params.service.split("&")[0];
      Admin.getService(serviceName).then((response) => {
        this.service = response.data.service;
        this.randomServices = response.data.random_services;
        this.mainImage = this.service.main_image.original_url;
      });
    },
    getAllServices() {
      Admin.getAllServices().then((response) => {
        this.services = response.data;
      });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.breadcrumb {
  background: transparent;
}

.ag__partnership-breadcrumbs {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: url(./../../assets/images/servicii/ag/bread-ag.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vw / 4);
  max-height: 100%;
}

.breadcrumb span {
  font-size: 27px;
  text-transform: uppercase;
  font-weight: 500;
}

.ag__partnership-page {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ag__partnership-services {
  width: 1171px;
}

.ag__partnership-content {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 60px 0px 80px 0px;
}

.ag__partnership-services-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: 6px;
}

.ag__partnership-title h1 {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 30px !important;
  letter-spacing: -1px;
}

.ag__partnership-subtitle h2 {
  font-family: "XMH Flama-Medium";
  color: #14afda;
  margin: 30px 0px 30px 0px;
  font-size: 36px;
  line-height: 40.93px;
  letter-spacing: -1.5px;
}

.ag__partnership-text--margin-top {
  margin-top: 110px;
}

.ag__partnership-text h5 {
  font-family: "XMH Flama-Medium";
  color: #424242;
  font-size: 17px;
  margin-bottom: 15px;
}

.ag__partnership-text p {
  font-family: "OpenSans-Regular";
  font-size: 15px;
  letter-spacing: -0.38px;
  line-height: 23px;
  width: 101%;
  margin-bottom: 22px;
}

.ag__partnership-figcaption {
  font-family: "OpenSans-Regular";
  width: auto;
  height: 66px;
  background-color: #e6e7e2;
  color: #72726f;
  padding-left: 41px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.ag__partnership-gallery {
  margin: 30px 0px;
}

.ag__partnership-gallery-list {
  width: 1171px;
  --gap: 30px;
  --num-cols: 3;
  --row-height: 200px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
  margin-bottom: 80px;
}

.ag__partnership-gallery-list-mobile {
  width: 1171px;
  --gap: 30px;
  --num-cols: 1;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
  margin-bottom: 50px;
}

.ag__partnership-gallery-list-mobile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-grid-col-2 {
  grid-column: span 2;
}

.image-grid-col-3 {
  grid-column: span 3;
}

.ag__partnership-gallery-list img {
  width: 100%;
}

.ag__partnership-services-right-column {
  margin-top: 13px;
}

.ag__partnership-more-services {
  background-color: #e6e7e2;
}

.other__services-title {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 24px;
  text-transform: uppercase;
  margin-top: 70px;
}

.other__services-list {
  width: 1171px;
}

.other__services-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 0px;
  margin-bottom: 80px;
}

.other__services-description h5 a {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 22px;
  margin: 15px 0px;
  letter-spacing: -0.46px;
}

.other__services-description a:hover {
  text-decoration: none;
}

h5.plus-title {
  margin-bottom: 5px !important;
}
.plus-title p {
  margin-bottom: 2px !important;
}

@media (max-width: 1366px) {
  .ag__partnership-services,
  .ag__partnership-gallery-list {
    width: 85% !important;
  }
  .ag__partnership-content {
    margin: 60px 0px 50px 0px;
  }
}

@media (max-width: 992px) {
  .ag__partnership-subtitle h2 {
    width: 100%;
    margin: 25px 0px 30px 0px;
    font-size: 28px;
    line-height: 32px;
  }
  .ag__partnership-title h1 {
    font-size: 20px;
  }
  .ag__partnership-text h5 {
    font-size: 14px;
    margin-bottom: 9px;
  }
  .ag__partnership-text p {
    font-size: 13px;
    line-height: 20px;
  }
}

@media (max-width: 767px) {
  .ag__partnership-services {
    width: 85%;
    display: flex;
    flex-direction: column-reverse;
    grid-row-gap: 35px;
  }
  .ag__partnership-content {
    margin-top: 30px;
  }
  .other__services-item {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 40px;
  }
}
</style>