<template>
  <div class="assembly__structures-page">
    <div class="assembly__structures-breadcrumbs">
      <b-breadcrumb :items="items"></b-breadcrumb>
    </div>
    <div class="assembly__structures-content">
      <div class="assembly__structures-services row">
        <div class="col-md-6 assembly__structures-services-left-column">
          <div class="assembly__structures-title">
            <h1 class="line-after">
              {{ $t("homepage.header.menuValues.services") }}
            </h1>
          </div>
          <div class="assembly__structures-subtitle">
            <h2>{{ service.name }}</h2>
          </div>
          <div class="assembly__structures-text" v-html="service.content"></div>
        </div>
        <div class="col-md-6 assembly__structures-services-right-column">
          <div class="assembly__structures-services-image">
            <img :src="mainImage" />
            <figcaption class="assembly__structures-figcaption">
              {{ service.image_title }}
            </figcaption>
          </div>
        </div>
      </div>
    </div>

    <AssemblyStructuresLightbox />

    <div
      class="
        assembly__structures-more-services
        w-100
        d-flex
        justify-content-center
      "
    >
      <div class="other__services-list">
        <div>
          <h1 class="line-after other__services-title">
            <span> {{ $t("homepage.anotherServ") }} </span>
          </h1>
        </div>
        <div class="other__services-item">
          <div
            class="other__services-description"
            v-for="item in services"
            :key="item.id"
            v-show="
              item.vue_components === 'large-doors' ||
              item.vue_components === 'industrial-walls'
            "
          >
            <a :href="item.slug + '&component=' + item.vue_components">
              <div v-for="media in item.media" :key="media.id">
                <img
                  v-if="media.collection_name === 'main_image'"
                  class="w-100"
                  :src="media.original_url"
                />
              </div>
              <h5 class="other__services-h5">{{ item.name }}</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssemblyStructuresLightbox from "@/components/AssemblyStructuresLightbox.vue";
import Admin from "@/apis/Admin.js";

export default {
  components: { AssemblyStructuresLightbox },

  name: "assembly-structures",
  data() {
    return {
      items: [],
      service: [],
      mainImage: "",
      randomServices: [],
      services: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getAssembly();
      this.getAllServices();
    });
  },
  methods: {
    getAssembly() {
      const serviceName =
        this.$router.history.current.params.service.split("&")[0];
      Admin.getService(serviceName).then((response) => {
        this.service = response.data.service;
        this.randomServices = response.data.random_services;
        this.mainImage = this.service.main_image.original_url;
      });
    },
    getAllServices() {
      Admin.getAllServices().then((response) => {
        this.services = response.data;
      });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.breadcrumb {
  background: transparent;
}

.assembly__structures-breadcrumbs {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: url(./../../assets/images/servicii/montaj-structuri/bread-montaj-structuri.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vw / 4);
  max-height: 100%;
}

.breadcrumb span {
  font-size: 27px;
  text-transform: uppercase;
  font-weight: 500;
}

.assembly__structures-page {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.assembly__structures-services {
  width: 1171px;
}

.assembly__structures-content {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.assembly__structures-services-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: 6px;
}

.assembly__structures-title h1 {
  color: #252525;
  font-family: "XMH Flama", monospace;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 30px !important;
  letter-spacing: -1px;
}

.assembly__structures-subtitle h2 {
  font-family: "XMH Flama-Medium";
  color: #14afda;
  width: 70%;
  margin: 30px 0px 30px 0px;
  font-size: 34px;
  line-height: 40.93px;
  letter-spacing: -1.3px;
}

.c-text-montaj {
  width: 101%;
}

.assembly__structures-text--margin-top {
  margin-top: 110px;
}

.assembly__structures-text h5 {
  font-family: "XMH Flama-Medium";
  color: #424242;
  font-size: 16px;
  line-height: 23px;
}

.assembly__structures-text p {
  font-family: "OpenSans-Regular";
  font-size: 15px;
  color: #424242;
  letter-spacing: -0.38px;
  margin-top: 30px;
}

.assembly__structures-figcaption {
  width: auto;
  height: 66px;
  background-color: #e6e7e2;
  color: #72726f;
  padding-left: 41px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.assembly__structures-gallery {
  margin: 90px 0px;
}

.assembly__structures-gallery-list {
  width: 1171px;
  --gap: 30px;
  --num-cols: 3;
  --row-height: 200px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
}

.assembly__structures-gallery-list-mobile {
  width: 1171px;
  --gap: 30px;
  --num-cols: 1;
  --row-height: 200px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  gap: var(--gap);
}

.assembly__structures-gallery-list-mobile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid-col-2 {
  grid-column: span 2;
}

.assembly__structures-gallery-list img {
  width: 100%;
}

.assembly__structures-services-right-column {
  margin-top: 13px;
}

.assembly__structures-more-services {
  background-color: #e6e7e2;
}

.other__services-list {
  width: 1171px;
}

.other__services-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 0px;
  margin-bottom: 80px;
}

.other__services-description h5 a {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 22px;
  margin: 17px 0px;
}

.other__services-title {
  color: #252525;
  font-family: "XMH Flama-Medium";
  font-size: 24px;
  margin-bottom: 35px !important;
  text-transform: uppercase;
  margin-top: 70px;
}

@media (max-width: 1440px) {
  .assembly__structures-subtitle h2 {
    width: 100%;
  }
}

@media (max-width: 1366px) {
  .assembly__structures-services,
  .assembly__structures-gallery-list {
    width: 85% !important;
  }
}

@media (max-width: 992px) {
  .assembly__structures-services {
    width: 85%;
  }
  .assembly__structures-title h1 {
    width: 20px;
  }
  .assembly__structures-subtitle h2 {
    margin: 25px 0px 30px 0px;
    font-size: 28px;
    line-height: 32px;
  }
  .assembly__structures-text h5 {
    font-size: 14px;
    line-height: 21px;
  }
  .assembly__structures-text p {
    font-size: 13px;
    margin-top: 24px;
  }
}

@media (max-width: 767px) {
  .assembly__structures-services {
    display: flex;
    flex-direction: column-reverse;
    grid-row-gap: 35px;
  }
  .assembly__structures-content {
    margin-top: 45px;
  }
  .assembly__structures-services-right-column {
    margin-top: 0px;
  }
}
</style>
